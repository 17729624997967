<template>
  <div>
    <div class="detail" id="detail">
      <div class="back" @click="jump">
        <div class="back__title">&lt; 返回课程列表</div>
      </div>
      <div class="video">
        <div class="video__title">{{detail.title}}</div>
        <video :src="detail.video" preload :poster="detail.videoCover" controls oncontextmenu="return false"> </video>
        <div class="video__share" @click.stop="share(detail.video)">
          <img src="../../assets/share@2x.png" alt="">
          <span> 分享/下载</span>
        </div>
      </div>
      <div class="reco" v-if="false">
        <div class="reco__title">为您推荐</div>
        <div class="reco__list">
          <div v-if="index < 3" class="reco__list__item" v-for="(item, index) in data.list" :index="item.id" @click="toDetail(item)">
            <img :src="item.videoCover" alt="">
            <div class="reco__list__item__desc">
              <div class="reco__list__item__desc__title">{{item.title}}</div>
              <div class="reco__list__item__desc__tag">恒亿精选课程</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'videoDetail',

  components: {},

  data() {
    return {
      detail: {},
    }
  },

  created() {},
  activated() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    if (sessionStorage.getItem('videoDetail')) {
      this.detail = JSON.parse(sessionStorage.getItem('videoDetail'))
      console.log('videoDetail', this.detail)
    }
  },
  methods: {
    jump() {
      this.$router.push('/hySchool')
    },
    share(url) {
      console.log(navigator.clipboard)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        return navigator.clipboard.writeText(url)
      } else {
        // 创建text area
        let textArea = document.createElement('textarea')
        textArea.value = url
        // 使text area不在viewport，同时设置不可见
        textArea.style.position = 'absolute'
        textArea.style.opacity = 0
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        return new Promise((res, rej) => {
          // 执行复制命令并移除文本框
          document.execCommand('copy') ? res() : rej()
          textArea.remove()
          this.$message('复制成功')
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.detail {
  padding-bottom: 0.53rem;
  background: #191919;
  .back {
    margin-top: 0.92rem;
    cursor: pointer;
    width: 100%;
    line-height: 0.93rem;
    background: #282828;
    border-radius: 0rem 0rem 0rem 0rem;
    // padding: 0 1.65rem;
    color: #8f8f8f;
    font-size: 0.19rem;
    box-sizing: border-box;
    &__title {
      width: 16rem;
      margin: 0 auto;
    }
  }
  .video {
    width: 16rem;
    margin: 0 auto;
    &__title {
      padding: 0.35rem 0;
      font-size: 0.27rem;
      color: #fff;
      box-sizing: border-box;
    }
    video {
      width: 100%;
      height: 9.47rem;
    }
    &__share {
      width: 100%;
      height: 0.77rem;
      font-size: #999;
      color: #999;
      padding-left: 0.53rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      background: #26262b;
      img {
        width: 0.3rem;
        height: 0.3rem;
        margin-right: 0.13rem;
      }
    }
  }
  .reco {
    width: 100%;
    background: #fff;
    &__title {
      width: 16rem;
      margin: 0 auto;
      font-size: 0.24rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      padding: 0.48rem 0 0.35rem 0;
    }
    &__list {
      margin: 0 auto;
      width: 16rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      &__item {
        display: flex;
        flex-direction: column;
        // margin-right: 0.4rem;
        width: 4.92rem;
        margin-bottom: 0.77rem;
        img {
          width: 4.92rem;
          height: 2.93rem;
          border-radius: 0.05rem 0.05rem 0 0;
        }
        &__desc {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 1.15rem;
          background: #fff;
          border-radius: 0 0 0.05rem 0.05rem;
          padding: 0 0.27rem;
          box-shadow: 0.01rem 0.01rem 0.07rem 0.03rem rgba(124, 124, 124, 0.07);
          &__title {
            font-size: 0.21rem;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }
          &__tag {
            background: #f2f4f6;
            padding: 0.04rem 0.18rem;
            border-radius: 0.05rem 0.05rem 0.05rem 0.05rem;
            opacity: 1;
            color: #666666;
            font-size: 0.13rem;
            font-family: PingFang SC-Regular, PingFang SC;
          }
        }
      }
    }
  }
}
</style>