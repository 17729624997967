<template>
  <div>
    <div class="title" v-if="!hideTitle">{{ activeName }}</div>
    <ul class="list">
      <li
        class="listItem"
        @click="handleClick(item)"
        v-for="item in list"
        :key="item.id"
      >
        <img :src="item.img" alt="" class="img" />
        <div class="desc">
          <div class="descTitle">{{ item.title }}</div>
          <div class="descText">{{ item.introduction }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    hideTitle: Boolean,
    handleClick: Function,
    list: Array,
  },
  computed: {
    activeName: function () {
      return this.$store.state.activeItem.name;
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  padding: 0.58rem 0;
  font-size: 0.38rem;
  line-height: 0.48rem;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #1d2087;
  border-bottom: 0.03rem solid #1d2087;
}
.list {
  cursor: pointer;
  box-sizing: border-box;
  padding: 0.46rem 0 0.66rem;
}
.listItem {
  display: flex;
  margin-bottom: 0.47rem;
  .img {
    flex-shrink: 0;
    width: 2.29rem;
    height: 1.71rem;
    border-radius: 0.25rem;
    margin-right: 0.36rem;
    box-sizing: border-box;
    overflow: hidden;
  }
  .descTitle {
    margin: 0.25rem 0 0.35rem;
    font-size: 0.25rem;
    line-height: 0.25rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #1d2087;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .descText {
    font-size: 0.2rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3e3a39;
    line-height: 0.43rem;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>