<template>
  <div>
    <img :src="activeItem.img" alt="" class="banner" />
    <div class="mainLayout">
      <div class="side"><SideMenus /></div>
      <Detail
        v-show="isDetail"
        :detail="detail"
        :quickChange="quickChange"
        :close="setIsDetail"
      >
        <div class="imgBox ql-editor" v-html="detail.content" />
      </Detail>
      <div class="content" v-show="!isDetail">
        <ListLine :list="data.list" :handleClick="handleDetail" />
        <div class="pagination" v-show="data.total > 0">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="data.total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainLayout from "@/components/MainLayout.vue";
import SideMenus from "@/components/SideMenus.vue";
import Detail from "@/components/Detail.vue";
import ListLine from "@/components/ListLine.vue";

import { getArticleListAPI, getArticleDetailAPI } from "@/api";

export default {
  components: {
    MainLayout,
    SideMenus,
    Detail,
    ListLine,
  },
  data() {
    return {
      isDetail: false,
      detail: {},
      pageNum: 1,
      pageSize: 5,
      data: {},
    };
  },
  created() {
    this.getList();
    console.log(sessionStorage.getItem('newsDetail'))
    if(sessionStorage.getItem('newsDetail')) {
      this.setIsDetail(true);
      this.detail = JSON.parse(sessionStorage.getItem('newsDetail'))
      sessionStorage.clear('newsDetail')
    }
  },
  methods: {
    async getList() {
      const { id } = this.activeItem;
      console.log(this.activeItem)
      const data = await getArticleListAPI({
        id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      this.data = data;
      const detailId = this.$route.query.id;
      if (detailId) {
        const list = data.list || [];
        const detail = list.filter((item) => {
          return item.id == detailId;
        });
        this.detail = detail[0];
        this.isDetail = true;
      }
      window.scrollTo(0, 0);
    },
    setIsDetail(isDetail) {
      this.isDetail = isDetail;
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    handleDetail(item) {
      this.setIsDetail(true);
      this.detail = item;
    },
    async quickChange(id, menuId) {
      const data = await getArticleDetailAPI({
        id,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        menuId,
      });
      console.log(this.detail);
      this.detail = data;
    },
  },
  computed: {
    activeItem: function () {
      return this.$store.state.activeItem;
    },
  },
  watch: {
    activeItem(newVal, oldVal) {
      console.log("监听activeItem")
      console.log(newVal, oldVal)
      if(!this.detail) {
        this.setIsDetail(false);
      }
      
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  height: 100%;
}
.mainLayout {
  position: relative;
  display: flex;
  min-height: 7rem;
  padding: 0 3.61rem 0 7.11rem;
  margin: 0.58rem 0;
  // background-color: #eeeeef;
  // background-image: url("../../assets/background.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center left;
}
.imgBox {
  /deep/ img {
    width: auto !important;
  }
}
.side {
  position: absolute;
  left: 3.65rem;
  top: 2.68rem;
}
.content {
  width: 100%;
  .title {
    padding: 0.58rem 0;
    font-size: 0.48rem;
    line-height: 0.48rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #1d2087;
    border-bottom: 0.03rem solid #1d2087;
  }
}
.list {
  cursor: pointer;
  box-sizing: border-box;
  padding: 0.46rem 0 0.66rem;
}
.listItem {
  display: flex;
  margin-bottom: 0.47rem;
  .img {
    flex-shrink: 0;
    width: 2.29rem;
    height: 1.71rem;
    border-radius: 0.25rem;
    margin-right: 0.36rem;
    border: 1px solid red;
    box-sizing: border-box;
    overflow: hidden;
  }
  .descTitle {
    padding: 0.25rem 0 0.35rem;
    font-size: 0.3rem;
    line-height: 0.3rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #1d2087;
  }
  .descText {
    font-size: 0.25rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3e3a39;
    line-height: 0.43rem;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 0.78rem;
}
</style>