<template>
  <div>
    <img :src="activeItem.img" alt="" class="banner" />
    <div class="content">
      <div class="content-left">
        <div class="left-item" :class="activeTag == item.name? 'active': ''" v-for="item in tags" :key="item.id" @click="tagChange(item)">{{ item.name }}</div>
      </div>
      <div class="content-right">
        <div class="content-right-title" v-if="richInfo">{{ richInfo.title }}</div>
        <div v-if="richInfo" v-html="richInfo.content"></div>
        <div v-else class="no-data">暂无内容</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRichListAPI } from '@/api'
export default {
  name: 'index',

  components: {},

  data() {
    return {
      tags: [],
      activeTag: '',
      list: [],
      richInfo: {},
      selectIndex: 0,
    }
  },
  computed: {
    activeItem() {
      return this.$store.state.activeItem
    },
  },
  watch: {
    '$store.state.menus'() {
      this.getTab()
    },
    $route(to, from) {
      this.getTab()
    },
  },
  created() {},
  mounted() {
    this.getTab()
  },

  methods: {
    // 获取tab
    getTab() {
      console.log('this.$route', this.$route)
      const initMenus = this.$store.state.menus
      const currentNav = this.$store.state.navUrl
      if (initMenus.length > 0 && currentNav) {
        console.log('******************')
        let arr = []
        const childList = initMenus.find((item) => item.path === currentNav)
        if (childList.child) {
          childList.child.forEach((item) => {
            item.alias = item.path.split('/')[2]
            // 跳转锚点
            if (item.path == this.$route.path) {
              setTimeout(() => {
                this.tagChange(item)
              }, 100)
            }
            arr.push(item)
          })
        }
        this.tags = arr
        this.selectIndex = sessionStorage.getItem('selectIndex')
          ? sessionStorage.getItem('selectIndex')
          : 0
        sessionStorage.setItem('selectIndex', 0)
        if (arr[0]) {
          console.log('arrIndex name', arr[this.selectIndex].name)
          this.activeTag = arr[this.selectIndex].name
        }

        this.getRichList()
      }
    },
    async getRichList() {
      // const { id } = this.$store.state.activeItem
      const { id } = JSON.parse(sessionStorage.getItem('ACTIVEITEM'))
      this.list = await getRichListAPI(id)
      console.log(this.activeTag)
      this.richInfo = this.list.find((item) => {
        console.log('item.id', item.title)
        return item.title == this.activeTag
      })
    },
    tagChange(info) {
      console.log('info.name', info.name)
      this.activeTag = info.name
      this.richInfo = this.list.find((item) => {
        console.log('item.id', item)
        if (item) {
          return item.title === info.name
        } else {
          return {}
        }
      })
      console.log('richInfo', this.richInfo)
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  width: 19rem;
  margin: 0.49rem auto;
  display: flex;
}
.content-left {
  width: 2.35rem;
  height: 10.35rem;
  background: #f8f8f8;
  padding: 0.27rem 0.17rem;
}
.left-item {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 0.19rem;
  color: #666666;
  margin-bottom: 0.24rem;
  text-align: center;
  cursor: pointer;
}
.active {
  color: #479ee3;
}
.content-right {
  flex: 1;
  margin-left: 0.4rem;
  padding-right: 2rem;
}
.content-right-title {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 0.22rem;
  font-weight: 500;
  margin-bottom: 20px;
}
.content-right {
  /deep/ img {
    width: 9.17rem;
  }
}
.no-data {
  text-align: center;
  margin-top: 200px;
}
</style>