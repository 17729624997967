<template>
  <div ref="content">
    <img :src="activeItem.img" alt="" class="banner" />
    <div class="inner" style="padding-bottom: 0">
      <Tabs :list="tags" :activeTag="activeTag" @tabChoose="tabChoose" />
    </div>
    <!-- 发展历程 -->
    <div class="inner--grey process">
      <div class="course" id="course">
        <img src="@/assets/about/history.png" alt="" class="course__img" />
        <!-- <div v-for="(item, index) in courseList" :key="index">
          <div class="course__year">{{ item.date }}</div>
          <div class="course__wire">
            <div class="course__block" v-for="(item, index) in item.child" :key="index" :class="{ 'course__block--right': item.direction == 'right' }">
              <img :src="item.img" alt="" class="course__icon" :class="{ 'course__icon--width': item.type == 2 }" />
              <img :src="item.icon" alt="" class="course__theSign" />
              <div class="info">
                <div class="info__moon">{{ item.date }}</div>
                <div class="info__wire"></div>
                <div class="info__introduce">
                  {{ item.introduce }}
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 集团架构 -->
    <div class="inner">
      <div class="team" id="team">
        <div class="title">集团架构</div>
        <div class="list">
          <div class="list__item" v-for="(item, index) in teamList" :key="index">
            <img :src="item.img" alt="" class="list__img" />
            <img :src="item.icon" alt="" class="list__icon" />
            <div class="list__info">
              <div class="list__name">{{ item.name }}</div>
              <div class="list__tag">
                <div v-for="(item, index) in item.tag" :key="index" class="list__tag__item">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 专业资质 -->
    <div class="inner--grey">
      <div class="certificate" id="certificate">
        <div class="title">专业资质</div>
        <div class="certificate__block">
          <div class="certificate__info" v-for="(item, index) in certificateList" :key="index">
            <img :src="item.url" alt="" class="certificate__img" />
            <div class="certificate__name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- SideBar -->
    <SideBar />
  </div>
</template>

<script>
import Tabs from '@/components/Tab.vue'
import SideBar from '@/components/SideBar.vue'
export default {
  components: {
    Tabs,
    SideBar,
  },
  data() {
    return {
      data: {},
      tags: [],
      activeTag: '',
      courseList: [
        {
          date: '2023',
          child: [
            {
              img: require('@/assets/about/courseIcon.jpg'),
              icon: require('@/assets/about/theSign.jpg'),
              date: '1月',
              introduce: '全国布局招商加盟目标500家火热进行中',
              direction: 'left',
            },
          ],
        },
        {
          date: '2022',
          child: [
            {
              img: require('@/assets/about/courseIcon1.jpg'),
              icon: require('@/assets/about/theSign.jpg'),
              date: '3月',
              introduce: '正式启动招商加盟',
              direction: 'right',
            },
          ],
        },
        {
          date: '2021',
          child: [
            {
              img: require('@/assets/about/courseIcon2.jpg'),
              icon: require('@/assets/about/theSign.jpg'),
              date: '11月',
              introduce: '启动全国性的领域分化和市场布局',
              direction: 'left',
            },
          ],
        },
        {
          date: '2020',
          child: [
            {
              img: require('@/assets/about/courseIcon3.jpg'),
              icon: require('@/assets/about/theSign.jpg'),
              date: '1月',
              introduce: '·将“科技+金融”“服务+生态”定义核心主业',
              direction: 'right',
            },
          ],
        },
        {
          date: '2019',
          child: [
            {
              img: require('@/assets/about/courseIcon4.jpg'),
              icon: require('@/assets/about/theSign.jpg'),
              date: '1月',
              introduce: '·开启集团化布局，完成规划重组',
              direction: 'left',
            },
          ],
        },
        {
          date: '2018',
          child: [
            {
              img: require('@/assets/logo.png'),
              icon: require('@/assets/about/theSign.jpg'),
              date: '8月',
              introduce: '·恒亿数科成立',
              direction: 'right',
              type: 2,
            },
          ],
        },
      ],
      certificateList: [
        {
          url: require('@/assets/about/certificate1.png'),
          name: '经营证券期货业务许可证',
        },
        {
          url: require('@/assets/about/certificate2.png'),
          name: '保险中介许可证',
        },
      ],
      teamList: [
        {
          name: '北京欧纳莱教育科技有限公司',
          tag: ['财教', '金融', '科技'],
          img: require('@/assets/about/team1.png'),
          icon: require('@/assets/about/icon1.png'),
        },
        {
          name: '水木林（北京）保险经纪有限公司',
          tag: ['保险'],
          img: require('@/assets/about/team2.png'),
          icon: require('@/assets/about/icon2.png'),
        },
        {
          name: '北京微动利基金销售有限公司',
          tag: ['基金', '理财'],
          img: require('@/assets/about/team3.png'),
          icon: require('@/assets/about/icon3.png'),
        },
        {
          name: '恒烁（北京）投资管理有限公司',
          tag: ['慈善', '基金'],
          img: require('@/assets/about/team4.png'),
          icon: require('@/assets/about/icon4.png'),
        },
        {
          name: '恒源家办管理咨询服务（北京） 有限公司',
          tag: ['家族办公室'],
          img: require('@/assets/about/team5.png'),
          icon: require('@/assets/about/icon5.png'),
        },
        {
          name: '恒亿数科（北京）科技发展集团 有限公司山西分公司',
          tag: ['金融', '服务'],
          img: require('@/assets/about/team6.png'),
          icon: require('@/assets/about/hy-logo.png'),
        },
        {
          name: '恒亿数科（北京）科技发展集团 有限公司上海分公司',
          tag: ['金融', '服务'],
          img: require('@/assets/about/team7.png'),
          icon: require('@/assets/about/hy-logo.png'),
        },
      ],
      topList: {
        course: '',
        team: '',
        certificate: '',
      },
    }
  },
  created() {},
  mounted() {
    window.scrollTo(0, 0)
    this.getTab()
    // 监听
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 获取tab
    getTab() {
      const initMenus = this.$store.state.menus
      const currentNav = this.$store.state.navUrl
      if (initMenus.length > 0 && currentNav) {
        let arr = []
        const childList = initMenus.find((item) => item.path === currentNav)
        if (childList.child) {
          childList.child.forEach((item) => {
            item.alias = item.path.split('/')[2]
            // 跳转锚点
            if (item.path == this.$route.path) {
              setTimeout(() => {
                this.tabChoose(item)
              }, 100)
            }
            arr.push(item)
          })
        }
        this.tags = arr
        this.activeTag = arr[0].name
      }
    },
    // 选择tab
    tabChoose(item) {
      this.activeTag = item.name
      var anchor = document.getElementById(item.alias)
      anchor.scrollIntoView({ behavior: 'smooth' })
    },
    handleScroll() {
      if (!document.getElementById('course')) {
        return
      }
      // 记录各元素位置
      this.topList.course = document.getElementById('course').offsetTop + 100
      this.topList.team = document.getElementById('team').offsetTop + 100
      this.topList.certificate =
        document.getElementById('certificate').offsetTop + 100
      if (this.topList.course > window.pageYOffset) {
        this.activeTag = this.tags[0].name
      } else if (this.topList.team > window.pageYOffset) {
        this.activeTag = this.tags[1].name
      } else {
        this.activeTag = this.tags[2].name
      }
    },
  },
  computed: {
    activeItem() {
      return this.$store.state.activeItem
    },
  },
  watch: {
    '$store.state.menus'() {
      this.getTab()
    },
    $route(to) {
      this.tags.forEach((item) => {
        if (item.path == to.path) {
          this.tabChoose(item)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.banner {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.86rem;
}
.inner {
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0.7rem 1.1rem 1.03rem;
}

.inner--grey {
  background: #f9fbfc;
}
.process {
  width: 100%;
  height: 8.57rem;
  background-image: url('~@/assets/about/processBg.png');
  background-size: 100% 8.57rem;
}
.course {
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 2.27rem 1.1rem 1.5rem;
  text-align: center;
  &__img {
    width: 16.08rem;
  }
  .course__year {
    width: 0.9rem;
    height: 0.41rem;
    line-height: 0.41rem;
    background: #479ee3;
    border-radius: 0.05rem 0.05rem 0.05rem 0.05rem;
    font-size: 0.21rem;
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    color: #ffffff;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .course__wire {
    width: 2px;
    background: #f0f0f0;
    padding-bottom: 1rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .course__block--right {
    flex-direction: row-reverse;
    justify-content: left;
    margin-left: -5.07rem !important;
    .info__moon {
      padding-left: 0 !important;
    }
    .info__introduce {
      padding-left: 0 !important;
    }
  }
  .course__block {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: -1.54rem;
    .course__theSign {
      width: 0.17rem;
      height: 0.17rem;
    }
    .course__icon {
      width: 0.67rem;
      height: 0.67rem;
      margin: 0 0.4rem;
    }
    .course__icon--width {
      width: 1.79rem;
      height: 0.6rem;
      margin: 0 0.4rem;
    }
    .info {
      width: 5.43rem;
      text-align: left;
      .info__moon {
        padding: 0.15rem 0.35rem;
        font-size: 0.19rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #479ee3;
      }
      .info__wire {
        width: 5rem;
        height: 2px;
        background: #d9d9d9;
      }
      .info__introduce {
        padding: 0.15rem 0.35rem;
        font-size: 0.19rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.team {
  text-align: center;
  .title {
    font-size: 0.32rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-top: 0.53rem;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .list__item {
      margin-top: 0.6rem;
      box-shadow: 0.01rem 0.01rem 0.07rem 0.03rem rgba(124, 124, 124, 0.07);
      border-radius: 0.05rem 0.05rem 0.05rem 0.05rem;
    }
    .list__img {
      width: 4.92rem;
      border-radius: 0.1rem 0.1rem 0 0;
    }
    .list__icon {
      display: block;
      width: auto;
      height: 0.24rem;
      margin: 0.52rem 0 0 0.25rem;
    }
    .list__info {
      display: flex;
      margin: 0.3rem 0.25rem 0.54rem 0.25rem;
      justify-content: space-between;
      .list__name {
        width: 2.9rem;
        text-align: left;
        font-size: 0.21rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .list__tag {
        height: 0.27rem;
        background: #f2f4f6;
        border-radius: 0.05rem 0.05rem 0.05rem 0.05rem;
        display: flex;
        align-items: center;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        padding: 0.05rem 0.1rem;
        .list__tag__item {
          line-height: 0.15rem;
          font-size: 0.13rem;
          padding: 0 0.05rem;
          border-right: 1.4px solid #b7b4b4;
        }
        .list__tag__item:last-child {
          border: none;
        }
      }
    }
  }
}
.certificate {
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0.74rem 1.1rem 1.8rem;
  text-align: center;
  .title {
    font-size: 0.32rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-top: 0.53rem;
  }
  .certificate__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .certificate__info {
      margin-top: 0.55rem;
      // margin-right: 1.6rem;
      .certificate__img {
        width: 3.6rem;
      }
      .certificate__name {
        font-size: 0.2rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-top: 0.23rem;
      }
    }
    .certificate__info:first-child {
      margin-right: 1.6rem;
    }
  }
}
</style>