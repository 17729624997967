<template>
  <div class="ResultContainer" v-if="keyWord">
    <div class="inner">
      <div class="title" v-show="!isDetail">
        共找到<span class="count">{{ count }}</span
        >个搜索结果
      </div>
      <div class="empty" v-show="count === 0 && !isDetail">
        <div class="resultTip">{{ `没有找到与“${this.keyWord}”相关结果` }}</div>
        <div class="recommend" v-if="recommendList && recommendList.length > 0">
          <div class="title">相关推荐</div>
          <ListLine
            :hideTitle="true"
            :list="recommendList"
            :handleClick="handleDetail"
          />
        </div>
      </div>
      <ListLine
        v-show="!isDetail"
        :list="list"
        :hideTitle="true"
        :handleClick="handleDetail"
      />
      <div class="detail">
        <Detail
          v-show="isDetail"
          :detail="detail"
          :quickChange="quickChange"
          :close="setIsDetail"
        >
          <div class="ql-editor" v-html="detail.content" />
        </Detail>
      </div>
    </div>
  </div>
  <Empty v-else />
</template>

<script>
import {
  searchEssayListAPI,
  getArticleDetailAPI,
  getEssaySortListAPI,
} from "@/api";
import ListLine from "@/components/ListLine.vue";
import Detail from "@/components/Detail.vue";
import Empty from "@/components/Empty.vue";

export default {
  components: {
    ListLine,
    Detail,
    Empty,
  },
  data() {
    return {
      keyWord: this.$route.params.keyWord,
      list: [],
      isDetail: false,
      detail: {},
      recommendList: [],
    };
  },
  created() {
    this.getList(this.keyWord);
    this.getArticleList();
  },
  computed: {
    count() {
      return this.list.length || 0;
    },
  },
  methods: {
    async getList(keyWord) {
      const data = await searchEssayListAPI({
        findName: keyWord,
      });
      this.list = data;
    },
    async getArticleList() {
      const data = await getEssaySortListAPI({ id: 19 });
      this.recommendList = data.slice(0, 2);
    },
    setIsDetail(isDetail) {
      this.isDetail = isDetail;
    },
    handleDetail(item) {
      this.setIsDetail(true);
      this.detail = item;
    },
    async quickChange(id, menuId) {
      const data = await getArticleDetailAPI({
        id,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        menuId,
      });
      this.detail = data;
    },
  },
};
</script>

<style lang="less" scoped>
.ResultContainer {
  padding: 0 2.87rem;
}
.inner {
  max-width: 1440px;
  margin: 0 auto;
}
.title {
  margin-top: 1.71rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #666666;
  line-height: 0.45rem;
}
.count {
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #1d2087;
  margin: 0 0.2rem;
}
.detail {
  margin-top: 0.9rem;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 0.78rem;
}
.empty {
  .resultTip {
    margin: 1.38rem 0 2.09rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #666666;
    line-height: 0.45rem;
    text-align: center;
  }
}
</style>