<template>
  <ul class="sideMenus">
    <li
      class="sideItem"
      v-for="item in childList"
      :key="item.id"
      :class="{ active: activeUrl === item.path }"
      @click="jump(item)"
    >
      <span class="text">{{ item.name }}</span>
    </li>
  </ul>
</template>

<script>
import router from "@/router";
export default {
  data() {
    return {};
  },
  methods: {
    jump(item) {
      console.log("jump item ")
      console.log(item)
      this.$store.commit("setActiveItem", item);
      sessionStorage.setItem("ACTIVEITEM", JSON.stringify(item));
      router.push(item.path);
    },
  },
  computed: {
    childList: function () {
      const initMenus = this.$store.state.menus;
      const currentNav = this.$store.state.navUrl;
      if (initMenus.length > 0 && currentNav) {
        const childList = initMenus.find((item) => item.path === currentNav);
        if (childList.child) {
          const arr = [childList, ...childList.child];
          return arr.filter((item) => item.disable === 0);
        }
        return [childList];
      }
    },
    activeUrl: function () {
      return this.$store.state.activeItem.path;
    },
  },
};
</script>

<style scoped lang="less">
.sideItem {
  cursor: pointer;
  text-align: center;
  width: 2.6rem;
  height: 1rem;
  line-height: 1rem;
}
.text {
  font-size: 0.2rem;
  font-weight: 400;
  color: #595757;
}
.active {
  background-image: url("../assets/selected.png");
  background-size: cover;
  .text {
    position: relative;
    left: 0.35rem;
    color: #1d2087;
  }
}
</style>