<template>
  <div class="container">
    <div class="titleWrap">
      <img src="../assets/back.png" alt="" class="img" @click="setIsDetail" />
      <div class="title">{{ detail.title }}</div>
    </div>
    <div class="time">{{ detail.createTime }}</div>
    <div class="content">
      <slot></slot>
    </div>
    <div class="quickChange" v-if="detail.beforeId || detail.afterId">
      <div
        class="next"
        v-if="detail.beforeTitle"
        @click="handleQuickChange(detail.beforeId, detail.menuId)"
      >
        {{ `上一篇：${detail.beforeTitle}` }}
      </div>
      <div
        class="next"
        v-if="detail.afterTitle"
        @click="handleQuickChange(detail.afterId, detail.menuId)"
      >
        {{ `下一篇：${detail.afterTitle}` }}
      </div>
    </div>

    <div v-if="recommendList && recommendList.length > 0" class="recommend">
      <div class="starWrap">
        <img class="star" src="../assets/star.png" alt="" />
        <div class="title">往期推荐:</div>
      </div>
      <ul class="list">
        <li
          class="listItem"
          v-for="item in recommendList"
          :key="item.id"
          @click="handleQuickChange(item.id)"
        >
          <div>
            <div class="title">{{ item.title }}</div>
            <div class="desc">
              {{ item.introduction }}
            </div>
          </div>
          <img src="../assets/arrowRight.png" alt="" class="arrow" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getEssaySortListAPI } from "@/api";
export default {
  props: {
    detail: {},
    close: Function,
    quickChange: Function,
  },
  data() {
    return {
      recommendList: [],
    };
  },
  created() {
    if (this.activeItem.menuType === 2) {
      this.getArticleList();
    }
  },
  methods: {
    setIsDetail() {
      this.close(false);
    },
    handleQuickChange(id, menuId) {
      window.scrollTo(0, 0);
      this.quickChange(id, menuId);
    },
    async getArticleList() {
      const data = await getEssaySortListAPI({ id: 19 });
      this.recommendList = data.slice(0, 2);
    },
  },
  computed: {
    activeItem: function () {
      return this.$store.state.activeItem;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  box-sizing: border-box;
  padding: 1.02rem 0 2.27rem;
}
.quickChange {
  margin: 0.78rem 0;
  padding: 0.78rem 0;
  border-top: 2px solid #42449a;
  border-bottom: 2px solid #42449a;
  display: grid;
  grid-row-gap: 0.3rem;
  .next {
    cursor: pointer;
    font-size: 0.22rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3e3a39;
  }
}
.titleWrap {
  display: flex;
  align-items: center;
  .img {
    cursor: pointer;
    width: 0.15rem;
    height: 0.27rem;
  }
  .title {
    font-size: 0.3rem;
    line-height: 0.3rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #231815;
    margin-left: 0.24rem;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.time {
  padding: 0.28rem 0 0.45rem;
  font-size: 0.2rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #3e3a39;
  line-height: 0.22rem;
}
.recommend {
  margin-top: 0.95rem;
}
.starWrap {
  display: flex;
  align-items: center;
  padding-bottom: 0.18rem;
  .star {
    width: 0.2rem;
    height: 0.2rem;
  }
  .title {
    margin-left: 0.07rem;
    font-size: 0.22rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3e3a39;
  }
}
.listItem {
  cursor: pointer;
  margin-top: 0.15rem;
  box-sizing: border-box;
  padding: 0.23rem 0.42rem;
  border: 2px solid #1d2088;
  border-radius: 0 0 0.25rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 0.2rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3e3a39;
    line-height: 0.2rem;
    margin-bottom: 0.15rem;
  }
  .desc {
    font-size: 0.2rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #727171;
    line-height: 0.2rem;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .img {
    width: 0.19rem;
    height: 0.24rem;
  }
  .arrow {
    width: auto;
  }
}
</style>