<template>
  <div>
    <img src="@/assets/group/topBg.png" alt="" class="banner" />
    <div class="top" id="hygh">
      <div class="title"><strong>科技，全方面助力金融服务</strong></div>
      <div class="introduce">
        <span class="introduce__name">恒亿规划APP</span> 是集团旗下专业技术团队独立设计研发，集专业投资、
        <br />
        财商教育、保险配置等功能为一体的综合金融服务平台，旨在为更多的普通家庭及个人客户提供专业的投资教育及金融服务。
      </div>
      <div class="download">
        <div class="download__left">
          <div class="download__name">
            <img src="@/assets/group/wechat.png" alt="" class="download__icon" />关注恒亿公众号
          </div>
          <img :src="companyInfo.qrcCode" alt="" class="download__img" />
        </div>
        <div class="download__left">
          <div class="download__name">
            <img src="@/assets/group/android.png" alt="" class="download__icon" />安卓下载
          </div>
          <img :src="companyInfo.hyghQrcCode" alt="" class="download__img" />
        </div>
        <div class="download__left">
          <div class="download__name">
            <img src="@/assets/group/ios.png" alt="" class="download__icon" />IOS下载
          </div>
          <img :src="companyInfo.hyghQrcCode" alt="" class="download__img" />
        </div>

      </div>
      <img src="@/assets/group/banner.png" alt="" class="img" />
    </div>
    <div class="middle">
      <!-- 模块一 -->
      <div class="module__bg1">
        <div class="module" id="wdl">
          <div class="left">
            <div><strong class="title">微动利基金APP</strong></div>
            <div class="introduce">再微小的力量，也能开启财富新动力</div>
            <div class="content">
              北京微动利APP是微动利基金销售有限公司旗下的官方一站式基金销售服务平台，与多家资管机构合作，为用户提供安全、专业、便捷的投资理财服务
            </div>
            <div class="btn">
              <div class="btn__name">扫码下载APP</div>
              <img :src="companyInfo.wdlQrcCode" alt="" class="btn__img" />
            </div>
          </div>
          <div class="right">
            <img src="@/assets/group/moduleImg1.png" alt="" class="img" />
          </div>
        </div>
      </div>
      <!-- 模块二 -->
      <div class="module__bg2">
        <div class="module" id="sml">
          <div class="right">
            <img src="@/assets/group/moduleImg2.png" alt="" class="img" />
          </div>
          <div class="left">
            <div><strong class="title">水木林保险APP</strong></div>
            <div class="introduce">给家人多份关爱，给生活多重保障</div>
            <div class="content">
              水木林保险APP是集团旗下专业技术团队独立设计研发的保险经纪平台，拥有中国银保监会核准颁发的《保险中介许可证》，携手国内知名保险公司为用户提供优质的保险服务，依托线上平台优势，让保险服务触手可及。
            </div>
            <div class="btn">
              <div class="btn__name">扫码下载APP</div>
              <img :src="companyInfo.smlQrcCode" alt="" class="btn__img" />
            </div>
          </div>
        </div>
      </div>
      <!-- 模块三 -->
      <div class="module__bg3">
        <div class="module" id="ydl">
          <div class="left">
            <div><strong class="title">原动利教育小程序</strong></div>
            <div class="introduce">知识原动利，生活添动力</div>
            <div class="content">
              原动利教育小程序是集团旗下专业技术团队独立设计研发，集教育培训、产品研发、课程在线学习及教育服务等于一体的金融知识平台。
            </div>
            <div class="btn">
              <div class="btn__name">扫码下载APP</div>
              <img :src="companyInfo.ydlQrcCode" alt="" class="btn__img" />
            </div>
          </div>
          <div class="right">
            <img src="@/assets/group/moduleImg3.png" alt="" class="img" />
          </div>
        </div>
      </div>
      <!-- 模块四 -->
      <div class="module__bg4">
        <div class="module" id="ygzs">
          <div class="right">
            <img src="@/assets/group/moduleImg4.png" alt="" class="img" />
          </div>
          <div class="left">
            <div><strong class="title">恒亿运管助手APP</strong></div>
            <div class="introduce">全能拓客助手，门店智能管家</div>
            <div class="content">
              “恒亿运管助手”APP是恒亿数科（北京）科技发展集团有限公司自主研发的具备数字化运营、营销、客户管理的服务平台，根据不同角色开放不同的权限及功能，精准连接客户与渠道的数据化内容，支持门店从营销到服务的线上智能管理。轻松实现以“精准获客、高效转化、高复购率”为核心的客户全周期连接，助力门店业绩的可持续增长。为终端客户提供更多服务，实现品牌与个人、团队角色的合作共赢。
            </div>
            <div class="btn">
              <div class="btn__name">扫码下载APP</div>
              <img :src="companyInfo.ygzsQrcCode" alt="" class="btn__img" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SideBar -->
    <!-- <SideBar /> -->
  </div>
</template>

<script>
import { getRichListAPI } from '@/api'
import SideBar from '@/components/SideBar.vue'
export default {
  data() {
    return {
      data: {
        tags: [],
        activeTag: '',
      },
    }
  },
  created() {
    setTimeout(() => {
      this.getData()
    })
  },
  components: {
    SideBar,
  },
  mounted() {
    window.scrollTo(0, 0)
    this.getTab()
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    // 获取tab
    getTab() {
      // 获取tags
      const initMenus = this.$store.state.menus
      const currentNav = this.$store.state.navUrl
      if (initMenus.length > 0 && currentNav) {
        let arr = []
        const childList = initMenus.find((item) => item.path === currentNav)
        if (childList.child) {
          childList.child.forEach((item) => {
            item.alias = item.path.split('/')[2]
            // 跳转锚点
            if (item.path == this.$route.path) {
              setTimeout(() => {
                this.tabChoose(item)
              }, 100)
            }
            arr.push(item)
          })
        }
        this.tags = arr
        this.activeTag = arr[0].name
      }
    },
    async getData() {
      const { id } = this.activeItem
      const data = await getRichListAPI(id)
      console.log(data)
      this.data = data[0] || { content: '' }
    },
    // 选择tab
    tabChoose(item) {
      this.activeTag = item.name
      var anchor = document.getElementById(item.alias)
      anchor.scrollIntoView({ behavior: 'smooth' })
    },
    // 监听页面滚动
    handleScroll(e) {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop

      if (scrollTop < 100) {
        // console.log(11111)
        // this.headerClass = false
      } else {
        // console.log(222)
        // this.headerClass = true
        // document.getElementById('header').style.backgroundColor =
        //   'rgba(255,255,255,1)!important'
        // document.getElementById('listItem').style.color =
        //   'rgba(0,0,0,1)!important'
      }
    },
  },
  computed: {
    activeItem() {
      return this.$store.state.activeItem
    },
    companyInfo() {
      return this.$store.state.companyInfo
    },
  },
  watch: {
    '$store.state.menus'() {
      this.getTab()
    },
    activeItem() {
      this.getData()
    },
    $route(to) {
      this.tags.forEach((item) => {
        if (item.path == to.path) {
          this.tabChoose(item)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.banner {
  margin-top: -0.92rem;
}
.top {
  width: 100vw;
  position: absolute;
  top: 1.8rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  .title {
    font-size: 0.6rem;
    font-weight: normal;
    color: #ffffff;
  }
  .introduce {
    margin-top: 0.15rem;
    font-size: 0.19rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 0.32rem;
    &__name {
      font-size: 0.27rem;
      font-weight: 900;
      color: #ffffff;
      line-height: 0.31rem;
      letter-spacing: 3px;
    }
  }
  .download {
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-top: 0.57rem;
    height: 1.1rem;
    .download__left:last-child {
      margin: 0;
    }
    .download__left {
      margin-right: 0.3rem;
      .download__icon {
        width: 0.19rem;
        margin-right: 0.05rem;
        vertical-align: middle;
        margin-top: -1px;
      }
      .download__name {
        background: #caff5a;
        padding: 0.15rem 0.1rem;
        border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
        float: left;
        vertical-align: middle;
      }
      .download__img {
        display: none;
        vertical-align: middle;
        width: 0.9rem;
        border: 0.1rem solid #caff5a;
        border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
        margin-top: -0.28rem;
        margin-left: -3px;
      }
    }
    .download__left:hover {
      .download__name {
        border-radius: 0.12rem 0 0 0.12rem;
      }
      .download__img {
        display: inline-block;
      }
    }
  }
  .img {
    width: 6.88rem;
  }
}
.middle {
  // max-width: 1440px;
  // margin: 0 auto;
  box-sizing: border-box;
  // padding: 0.74rem 1.1rem 1.03rem;
  .module {
    max-width: 1240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 1.5rem;
    margin: 0 auto;
    padding: 1.33rem 0 1.33rem;
  }
  .left {
    .title {
      font-size: 0.36rem;
      font-weight: 900;
      color: #333333;
      position: relative;
      z-index: 10;
      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: '';
        width: 100%;
        height: 0.15rem;
        background: linear-gradient(270deg, #caff5a 0%, #c4e7fc 100%);
        z-index: -1;
      }
    }
    .introduce {
      margin-top: 0.23rem;
      font-size: 0.19rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    .content {
      width: 7.8rem;
      margin-top: 1.45rem;
      font-size: 0.16rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
    .btn {
      margin-top: 0.8rem;
      .btn__name {
        background: #60b7f8;
        padding: 0.15rem 0.1rem;
        border-radius: 0.12rem 0 0 0.12rem;
        float: left;
        color: #fff;
      }
      .btn__img {
        vertical-align: middle;
        width: 0.9rem;
        height: 0.9rem;
        border: 0.1rem solid #60b7f8;
        border-radius: 0 0.12rem 0.12rem 0.12rem;
      }
    }
  }
  .right {
    .img {
      width: 7rem;
    }
  }
}
.module__bg1 {
  width: 100%;
  height: 8.61rem;
  background-image: url('~@/assets/group/moduleBg1.png');
  background-size: 100%;
}
.module__bg2 {
  width: 100%;
  height: 8.61rem;
  background-image: url('~@/assets/group/moduleBg2.png');
  background-size: 100%;
}
.module__bg3 {
  width: 100%;
  height: 8.61rem;
  background-image: url('~@/assets/group/moduleBg3.png');
  background-size: 100%;
}
.module__bg4 {
  width: 100%;
  height: 8.61rem;
  background-image: url('~@/assets/group/moduleBg4.png');
  background-size: 100%;
}
</style>>