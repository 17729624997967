<template>
  <div class="container">
    <img :src="activeItem.img" alt="" class="banner" />
    <div style="background:#fff;">
      <ul class="nav">
        <li class="nav__item" :class="{'active': index === curTabIndex}" v-for="(item,index) in list" :key="item.id" @click="goAnchor(item.id,index)">{{item.name}}</li>
      </ul>
    </div>
    <div class="contact" :id="list[0] && list[0].id">
      <div class="solgan">
        <div class="solgan__text1">致力于成为国内</div>
        <div class="solgan__text2">领先的科技型综合金融服务提供商</div>
        <div class="solgan__line"></div>
      </div>
      <div class="info">
        <div class="info__tel">
          <div class="info__tel__value">{{companyInfo.phone}}</div>
          <div class="info__tel__key">咨询电话</div>
        </div>
        <div class="info__line"></div>
        <div class="info__addr">
          <div class="info__addr__value">{{companyInfo.address}}</div>
          <div class="info__addr__key">总部地址</div>
        </div>
      </div>
      <div class="map">
        <img class="map" src="@/assets/map.jpg" alt="">
        <!-- <baidu-map class="map" :zoom="zoom" :center="center" @ready="handler">
          <bm-marker :position="center" animation="BMAP_ANIMATION_BOUNCE">
          </bm-marker>
        </baidu-map> -->
      </div>
    </div>
    <!-- <div class="join" :id="list[1] && list[1].id">
      <div class="title">招贤纳士</div>
      <div class="list">
        <div class="list__item" :class="{'active': index === curJobIndex}" v-for="(item, index) in jobList" :key="index" @click="jobChange(item.detail, index)">
          <div class="list__item__title">{{item.position}}</div>
          <div class="list__item__addr">{{item.addr}}</div>
          <div class="list__item__addr list__item__ask">{{item.ask}}</div>
        </div>
      </div>
      <div class="position">
        <div class="list">
          <div class="list__item" :class="{'active': index === curJobIndex}" v-for="(item, index) in jobList" :key="index" @click="jobChange(item.detail, index)">
            <div class="list__item__title">{{item.position}}</div>
            <div class="list__item__addr">{{item.addr}}</div>
            <div class="list__item__addr list__item__ask">{{item.ask}}</div>
          </div>
        </div>
        <div class="detail">
          <div class="detail__addr">地点：{{jobDetail.addr}}</div>
          <div class="detail__title">您的工作内容</div>
          <div class="detail__content">
            <div v-for="(item, index) in jobDetail.work" :key="item">{{index + 1 + '.' + item}}；</div>
          </div>
          <div class="detail__title">我们对你的期望</div>
          <div class="detail__content">
            <div v-for="(item, index) in jobDetail.desire" :key="item">{{index + 1 + '.' + item}}；</div>
          </div>
        </div>
      </div>

      <div>

      </div>
    </div> -->
    <div class="phone">
      联系方式：{{companyInfo.phone}}
    </div>
    <SideBar />
  </div>
</template>

<script>
import SideBar from '@/components/SideBar.vue'
export default {
  components: {
    SideBar,
  },
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 3,
      curTabIndex: 0,
      list: [],
      jobList: [
        {
          position: '招商经理',
          addr: '四川、山东、河南、江苏、江西、浙江、安徽、河北等省域',
          ask: '经验3年以上｜本科｜全职',
          detail: {
            addr: '四川、山东、河南、江苏、江西、浙江、安徽、河北等省域',
            work: [
              '完成公司下达的年度招商加盟店数量和质量目标',
              '在所负责区域有一定的客户资源，具备独立完成意向加盟商拜访，邀约，洽谈，签约的能力',
              '远程、实地与加盟商保持沟通，辅导门店日常经营，协助加盟商解决经营中出现的问题，督导加盟商合规经营',
              '负责招商、记录加盟商档案、整理加盟商资料',
              '具备一定的市场调研，规划能力，能够在公司资源的协助下进行对市场信息的收集并完成对所负责区域的渠道规划工作',
              '负责多省招商工作，实施所辖招商经理团队的日常管理',
            ],
            desire: [
              '全日制本科以上学历，年龄28-35岁，具备相关行业资源、工作经验优秀者可酌情放宽',
              '5年以上销售经验；3年以上加盟招商、渠道拓展工作经验者优先',
              '具备较强销售技巧、谈判技巧、营销技巧、策划能力与运作能力',
              '具备较强的沟通表达能力和应变能力，团队协作力强以及抗压能力强',
              '学习能力强，稳定性高',
            ],
          },
        },
      ],
      jobDetail: {},
      curJobIndex: 0,
    }
  },
  computed: {
    companyInfo() {
      return this.$store.state.companyInfo
    },
    activeItem: function () {
      return this.$store.state.activeItem
    },
    childList: function () {
      const initMenus = this.$store.state.menus
      const currentNav = this.$store.state.navUrl
      if (initMenus.length > 0 && currentNav) {
        const childList = initMenus.find((item) => item.path === '/contactUs')
        console.log('childList**************', childList)
        if (childList.child) {
          const arr = [childList, ...childList.child]
          return arr.filter((item) => item.disable === 0)
        }
        return [childList]
      }
    },
  },
  watch: {
    $route(to) {
      console.log('$router')
      console.log()
      this.list.forEach((item, index) => {
        if (item.path == to.path) {
          console.log(item.id)
          this.goAnchor(item.id, index)
        }
      })
    },
    childList: {
      handler() {
        console.log('childList发生变化')
        this.getTabs(this.childList)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    this.jobDetail = this.jobList[0].detail
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true) // 监听（绑定）滚轮滚动事件
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll) //离开页面需要移除这个监听的事件
  },
  methods: {
    //锚点跳转
    goAnchor(selector, index) {
      this.curTabIndex = index
      /*参数selector是id选择器（#anchor14）*/
      console.log(selector)
      const top = document.getElementById(selector).offsetTop
      const header = document.getElementById('header').clientHeight
      console.log(top, header)
      window.scrollTo({
        top: top - header,
        behavior: 'smooth',
      })
    },
    // handler({ BMap, map }) {
    //   this.center.lng = 116.48376176979907
    //   this.center.lat = 39.91787212499455
    //   this.zoom = 16
    // },
    // 监听滚动条
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop < 300) {
        this.curTabIndex = 0
      }
    },
    getTabs(arr) {
      console.log('arr*********', arr)
      arr.forEach((item) => {
        if (item.child) {
          this.list = []
          item.child.forEach((item, index) => {
            console.log('item.path', item.path, item.name)
            if (item.path == this.$route.path) {
              setTimeout(() => {
                this.goAnchor(item.id, index)
              }, 200)
            }
            let obj = {}
            obj.name = item.name
            obj.id = item.id
            obj.path = item.path
            this.list.push(obj)
            console.log(this.list)
          })
        }
      })
    },
    jobChange(detail, index) {
      this.jobDetail = detail
      this.curJobIndex = index
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  background: #f9fbfc;
  margin-top: 0.92rem;
  overflow: hidden;
}
.banner {
  width: 100%;
}
.nav {
  // width: 100%;
  width: 18rem;
  margin: 0 auto;
  height: 1.12rem;
  display: flex;
  align-items: center;
  background: #fff;
  font-size: 0.21rem;
  color: #333333;
  box-sizing: border-box;
  // padding-left: 2.5rem;
  cursor: pointer;
  &__item {
    margin-right: 1.32rem;
    line-height: 1.12rem;
  }
  .active {
    position: relative;
    color: #479ee3;
    font-weight: 600;
    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      width: 100%;
      background-color: #479ee3;
      height: 2px;
    }
  }
}
.contact {
  .solgan {
    color: #479ee3;
    font-family: PingFang SC-Bold;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 2px;
    padding: 1.07rem 0 0.68rem;
    box-sizing: border-box;
    &__text1 {
      font-size: 0.29rem;
    }
    &__text2 {
      font-size: 0.4rem;
    }
    &__line {
      width: 0.4rem;
      height: 0.04rem;
      background: #479ee3;
      border-radius: 0rem 0rem 0rem 0rem;
      margin-top: 0.11rem;
    }
  }
  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.45rem;
    &__tel {
      width: 1.7rem;
      &__value {
        font-size: 0.21rem;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #000000;
        // width: 2.54rem;
        text-align: right;
        height: 0.47rem;
        margin-bottom: 0.21rem;
      }
      &__key {
        font-size: 0.19rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        // width: 2.54rem;
        text-align: right;
      }
    }
    &__line {
      width: 0.01rem;
      height: 0.4rem;
      background: #d9d9d9;
      border-radius: 0rem 0rem 0rem 0rem;
      margin: 0 0.4rem;
    }
    &__addr {
      &__value {
        width: 2.74rem;
        font-size: 0.21rem;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #000000;
        height: 0.47rem;
        letter-spacing: 1px;
        margin-bottom: 0.21rem;
      }
      &__key {
        font-size: 0.19rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .map {
    width: 7.01rem;
    // height: 4.67rem;
    margin: 0 auto 0.56rem;
  }
}
.join {
  width: 100%;
  background: #fff;
  .title {
    font-size: 0.32rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    text-align: center;
    padding: 0.67rem 0 0.53rem;
  }
  .position {
    width: 18rem;
    margin: 0 auto 0;
    display: flex;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 0.81rem;
  }
  .list {
    display: flex;
    justify-content: center;
    // align-items: center;
    // border-bottom: 1px solid #d9d9d9;
    flex-wrap: wrap;
    padding-bottom: 0.5rem;
    &__item {
      // cursor: pointer;
      width: 3rem;
      // height: 1.76rem;
      padding: 0.21rem 0.29rem;
      margin-bottom: 0.3rem;
      &__title {
        font-size: 0.27rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #479ee3;
      }
      &__addr {
        font-size: 0.16rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.31rem;
        margin-top: 0.23rem;
      }
      &__ask {
        margin-top: 0.15rem;
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 0.29rem;
      }
    }
    .active {
      // background: #f9fbfc;
    }
  }
  .detail {
    flex: 1;
    height: 6.57rem;
    background: #f9fbfc;
    padding: 0.4rem 0.4rem 0.4rem 0.29rem;
    margin-left: 0.68rem;
    &__addr {
      font-size: 0.16rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.19rem;
      margin-bottom: 0.4rem;
    }
    &__title {
      font-size: 0.21rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #479ee3;
      margin-bottom: 0.2rem;
    }
    &__content {
      font-size: 0.16rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 0.4rem;
      white-space: pre-wrap;
      line-height: 0.4rem;
    }
  }
}
.phone {
  background: #fff;
  text-align: center;
  font-size: 0.21rem;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #479ee3;
  padding: 0.53rem 0 0.63rem;
}
</style>