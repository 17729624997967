<template>
  <div ref="content">
    <img :src="activeItem.img" alt="" class="banner" />
    <div class="inner">
      <div class="module">
        <div
          class="left"
          @mouseover="everMouseover(1)"
          @mouseleave="elevMouseleave(1)"
        >
          <img :src="visionImg" alt="" />
          <div class="info">
            <div class="info__title">企业愿景</div>
          </div>
          <transition name="bounce">
            <div class="introduce" v-show="visionShow">
              致力于成为国内领先的科技型综合金融服务提供商
            </div>
          </transition>
        </div>
        <div
          class="right"
          @mouseover="everMouseover(2)"
          @mouseleave="elevMouseleave(2)"
        >
          <img :src="valuesImg" alt="" />
          <div class="info">
            <div class="info__title">价值观</div>
          </div>
          <transition name="bounce">
            <div class="introduce" v-show="valuesShow">
              诚信务实 创新开放 团结精作 晋魂传承
            </div>
          </transition>
        </div>
      </div>
    </div>
    <!-- SideBar -->
    <SideBar />
  </div>
</template>

<script>
import Tabs from "@/components/Tab.vue";
import SideBar from "@/components/SideBar.vue";
export default {
  components: {
    Tabs,
    SideBar,
  },
  data() {
    return {
      visionImg: require("@/assets/culture/bg2.png"),
      valuesImg: require("@/assets/culture/bg3.png"),
      visionShow: true,
      valuesShow: false,
    };
  },
  created() {},
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    //鼠标移入触发
    everMouseover: function (id) {
      if (id == 1) {
        this.visionShow = true;
        this.visionImg = require("@/assets/culture/bg1.png");
      } else {
        this.valuesShow = true;
        this.valuesImg = require("@/assets/culture/bg1.png");
      }
    },
    //鼠标移出触发
    elevMouseleave: function (id) {
      if (id == 1) {
        this.visionShow = false;
        this.visionImg = require("@/assets/culture/bg2.png");
      } else {
        this.valuesShow = false;
        this.valuesImg = require("@/assets/culture/bg3.png");
      }
    },
  },
  computed: {
    activeItem() {
      return this.$store.state.activeItem;
    },
  },
  watch: {
    $route(to) {
      this.tags.forEach((item) => {
        if (item.path == to.path) {
          this.tabChoose(item);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0.86rem;
}
.inner {
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0.5rem 1.1rem 1.03rem;
  .module {
    display: flex;
    justify-content: space-between;
    margin: 0.7rem 0 1rem 0;
    .left {
      cursor: pointer;
      width: 7.8rem;
      position: relative;
      .info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .info__title {
          font-size: 0.32rem;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
          &::after {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -0.02rem;
            content: "";
            width: 0.27rem;
            height: 0.04rem;
            background: #fff;
            z-index: -1;
          }
        }
      }
      .introduce {
        width: 7rem;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 1rem;
        font-size: 0.21rem;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 0.38rem;
      }
    }
    .right {
      width: 7.8rem;
      position: relative;
      top: 0;
      cursor: pointer;
      .info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .info__title {
          font-size: 0.32rem;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
          &::after {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -0.02rem;
            content: "";
            width: 0.27rem;
            height: 0.04rem;
            background: #fff;
            z-index: -1;
          }
        }
      }
      .introduce {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 1rem;
        font-size: 0.21rem;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 0.38rem;
      }
    }
  }
}
// 企业愿景
.bounce-enter-active {
  animation: bounce-in .8s;
}
.bounce-leave-active {
  animation: bounce-in 0s;
}
@keyframes bounce-in {
  0% {
    margin-top: 100px;
  }
  50% {
    margin-top: 50px;
  }
  100% {
    margin-top: 09;
  }
}
</style>