<template>
  <div class="emptyContainer">
    <div class="tip">暂无数据</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.emptyContainer {
  background-image: url("../assets/empty.png");
  width: 100%;
  padding: 4.14rem 0 6.24rem;
  background-size: contain;
  background-repeat: no-repeat;
}
.tip {
  text-align: center;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 0.42rem;
}
</style>