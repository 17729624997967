<template>
  <div class="container">
    <img :src="activeItem.img" alt="" class="banner" />
    <div class="inner" style="padding-bottom: 0">
      <Tabs :list="tags" :activeTag="activeTag" @tabChoose="tabChoose" />
    </div>
    <div class="box">
      <div class="list">
        <div class="item" v-for="item in list" :key="item.id" @click="toDetail(item.link)">
          <img :src="item.img" alt="">
          <div class="item__info">
            <div class="item__title">{{item.title}}</div>
            <div class="item__btn">查看详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="total" @current-change="handleCurrentChange">
      </el-pagination>
    </div>

    <SideBar />
  </div>
</template>

<script>
import { productList } from '@/api'
import SideBar from '@/components/SideBar.vue'
import Tabs from '@/components/Tab.vue'
export default {
  components: {
    SideBar,
    Tabs,
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      tags: [],
      activeTag: '',
      id: '', // 当前选中的菜单id
      total: 0,
      list: [],
    }
  },
  computed: {
    activeItem() {
      this.pageNum = 1
      return this.$store.state.activeItem
    },
  },
  watch: {
    '$store.state.menus'() {
      this.getTab()
    },
    $route(to) {
      this.tags.forEach((item) => {
        if (item.path == to.path) {
          this.tabChoose(item)
        }
      })
    },
  },
  filters: {
    dateFormat(val) {
      let arr = val.split(' ')
      return arr[0]
    },
  },

  created() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },
  mounted() {
    window.scrollTo(0, 0)
    this.getTab()
    // window.addEventListener('scroll', this.handleScroll, true) // 监听（绑定）滚轮滚动事件
  },
  destroyed() {
    // window.removeEventListener('scroll', this.handleScroll) //离开页面需要移除这个监听的事件
  },
  methods: {
    // handleScroll() {
    //   let scrollTop =
    //     document.documentElement.scrollTop || document.body.scrollTop
    //   this.scrollTop = scrollTop
    // },
    toDetail(url) {
      console.log('产看产品详情', url)
      window.open(url, '_blank')
      // window.location.href = url
    },
    async getList(id) {
      const { list, total } = await productList(id, this.pageNum, this.pageSize)
      this.total = total
      this.list = list
      // if (this.pageNum > 1) {
      //   this.list = [...this.list, ...list]
      // } else {

      //   this.list = list
      // }
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList(this.id)
    },
    // 获取tab
    getTab() {
      const initMenus = this.$store.state.menus
      const currentNav = this.$store.state.navUrl
      if (initMenus.length > 0 && currentNav) {
        let arr = []
        const childList = initMenus.find((item) => item.path === currentNav)
        console.log('childList', childList)
        if (childList.child) {
          childList.child.forEach((item) => {
            item.alias = item.path.split('/')[2]
            // 跳转锚点
            if (item.path == this.$route.path) {
              setTimeout(() => {
                this.tabChoose(item)
              }, 100)
            }
            arr.push(item)
          })
        }
        this.tags = arr
        this.activeTag = arr[0].name
        this.id = arr[0].id
        this.getList(arr[0].id)
      }
    },
    // 选择tab
    tabChoose(item) {
      this.activeTag = item.name
      this.pageNum = 1
      this.id = item.id
      this.getList(item.id)
    },
  },
  computed: {
    activeItem() {
      return this.$store.state.activeItem
    },
  },
  watch: {
    '$store.state.menus'() {
      this.getTab()
    },
    $route(to) {
      this.tags.forEach((item) => {
        if (item.path == to.path) {
          this.tabChoose(item)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  background: #fff;
  margin-top: 0.92rem;
  overflow: hidden;
}
.banner {
  width: 100%;
}
.inner {
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0.7rem 1.1rem 1.03rem;
}
.list {
  width: 18.8rem;
  display: flex;
  margin: 0.32rem auto 0;
  padding: 0.2rem 0.2rem 0.3rem;
  // padding: 0.53rem 0.5rem 0.3rem;
  box-sizing: border-box;
  min-height: 2rem;
  flex-wrap: wrap;

  .item {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 3.49rem;
    box-shadow: 0 0 8px rgba(34, 30, 31, 0.3);
    margin: 0 10px 20px 0;
    border-bottom: 2px solid #479ee3;

    img {
      width: 3.49rem; // 262
      height: 2.63rem; // 197
    }
    &__info {
      width: 3.49rem; // 262
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
    &__title {
      height: 0.6rem;
      font-size: 0.213rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      box-sizing: border-box;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    &__btn {
      width: 1.17rem;
      height: 0.45rem;
      line-height: 0.45rem;
      background: #479ee3;
      font-size: 0.19rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      margin: 10px 0 10px;
    }
  }
}
.pagination {
  display: flex;
  justify-content: right;
  margin: 20px 100px 0.78rem 0;
}
</style>