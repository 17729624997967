<template>
  <div class="mainLayout">
    <div class="side"><SideMenus /></div>
    <div class="container">
      <slot>loading...</slot>
    </div>
  </div>
</template>

<script>
import SideMenus from "@/components/SideMenus.vue";
export default {
  components: { SideMenus },
};
</script>

<style lang="less" scoped>
.mainLayout {
  position: relative;
  display: flex;
  padding: 0 2.12rem 0 7.11rem;
  margin: 0.58rem 0;
  min-height: 7rem;
  background-color: #fff;
  // background-image: url("../assets/background.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center left;
}
.side {
  position: absolute;
  left: 3.65rem;
  top: 2.68rem;
}
.container {
  width: 100%;
  height: 100%;
  max-width: 900px;
  transform: translateY(-0.36rem);
}
</style>>