<template>
  <div>
    <div class="inner--grey">
      <div class="header">
        <div class="header__return" @click="jump()">
          < 返回首页</div>
            <div class="header__title">{{ detail.title }}</div>
        </div>
      </div>
      <div class="inner">
        <div class="inner__title">{{ detail.title }}</div>
        <div v-html="detail.content"></div>
      </div>
    </div>
</template>

<script>
import router from '@/router'
export default {
  data() {
    return {
      detail: '',
    }
  },
  created() {
    console.log('新闻详情created')
    sessionStorage.setItem('ACTIVEITEM', JSON.stringify({ path: '/news' }))
    // if (sessionStorage.getItem('newsDetail')) {
    //   this.detail = JSON.parse(sessionStorage.getItem('newsDetail'))
    // }
  },
  activated() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    if (sessionStorage.getItem('newsDetail')) {
      this.detail = JSON.parse(sessionStorage.getItem('newsDetail'))
    }
  },
  methods: {
    jump() {
      router.push('/')
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ img {
  width: 9.17rem;
}
.inner {
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0.41rem 1.6rem;
  &__title {
    font-size: 0.24rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 0.28rem;
  }
}
.inner--grey {
  background: #f9fbfc;
  margin: 0 auto;
  box-sizing: border-box;
}
.header {
  padding: 0.24rem 1.6rem;
  max-width: 1440px;
  margin: 0.92rem auto 0 auto;
  box-sizing: border-box;
  .header__return {
    cursor: pointer;
    font-size: 0.19rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  .header__title {
    margin-top: 0.31rem;
    font-size: 0.24rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
}
</style>